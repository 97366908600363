// playerUtils.js
import { addBreadcrumb } from '@sentry/browser';

import { getKeySystem } from './getDrmInfo.js';
import {
  COM_WIDEVINE_ALPHA,
  COM_MICROSOFT_PLAYREADY,
  COM_MICROSOFT_PLAYREADY_HARDWARE,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
  COM_APPLE_FPS,
} from '../resource/drmConstants.js';

import getResourceUrl from '../resource/getResourceUrl.js';
import { WATCH } from '../resource/resourceUrlTypeConstants.js';
import { player as playerDebug } from '../resource/debug.js';
import { PRESET_PREVIEW } from './liveStreamConstants.js';
import { Severity } from '../resource/sentry.js';

const playerDebugLog = playerDebug.extend('log:playerUtils');
const playerLog = ({ messages, data }) => {
  addBreadcrumb({
    category: 'player',
    level: Severity.Info,
    message: messages.join(' '),
    data,
  });
  playerDebugLog(...messages, data);
};

const videoTypeKeys = {
  DASH: 'dash',
  HLS: 'hls',
  MP4: 'mp4',
  FLV: 'flv',
};
const videoTypes = {
  [videoTypeKeys.DASH]: 'application/dash+xml',
  [videoTypeKeys.HLS]: 'application/x-mpegURL',
  [videoTypeKeys.MP4]: 'video/mp4',
  [videoTypeKeys.FLV]: 'video/x-flv',
};
const AUDIO_CONTENT_TYPE = 'audio/mp4; codecs="mp4a.40.2"'; // TODO: remote config
const VIDEO_CONTENT_TYPE = 'video/mp4; codecs="avc1.640028"'; // TODO: remote config

let isHlsSupported;
/**
 *
 * @param {HTMLVideoElement} videoElement
 */
const checkHlsSupported = videoElement => {
  if (typeof isHlsSupported === 'undefined') {
    isHlsSupported =
      videoElement?.canPlayType(videoTypes[videoTypeKeys.HLS]).length > 0;
  }

  return isHlsSupported;
};

const EVENT_MAP = {
  onAbort: 'abort',
  onPlaying: 'playing',
  onCanPlay: 'canplay',
  onCanplaythrough: 'canplaythrough',
  onDurationChange: 'durationchange',
  onFullscreenChange: 'fullscreenchange',
  onLoadStart: 'loadstart',
  onLoadedData: 'loadeddata',
  onProgress: 'progress',
  onSuspend: 'suspend',
  onVolumeChange: 'volumechange',
  onWaiting: 'waiting',
  onSeeked: 'seeked',
  onEnterPictureInPicture: 'enterpictureinpicture',
  onLeavePictureInPicture: 'leavepictureinpicture',
  onSeeking: 'seeking',
  onTimeupdate: 'timeupdate',
};

const TRACK = {
  Preview: 0,
  SD: 1,
  HD: 2,
  Audio: 3,
};

const LOAD_STATE = {
  Loading: 'loading',
  Loaded: 'loaded',
  CanPlay: 'canplay',
  CanPlaythrough: 'canplaythrough',
};

const PLAY_STATE = {
  Pause: 'pause',
  Play: 'play',
  Playing: 'playing',
  Waiting: 'waiting',
  Ended: 'ended',
};

const defaultConfig = {
  controls: false,
  autoplay: 'muted',
  loop: true,
  techOrder: ['html5'],
  children: {
    mediaLoader: true, // minimal children
    posterImage: true,
    controlBar: {
      children: [],
    }, // minimal children
  },
  html5: {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
  },
};

/**
 * Get the time format
 * @param {number} duration - video duration in second
 */
const getTimeFormat = duration => {
  const MINUTE = 60;
  const HOUR = 60 * MINUTE;
  if (duration >= HOUR) {
    return 'HH:mm:ss';
  }
  return 'mm:ss';
};

/**
 * @typedef {Object} Source
 * @property {string} type
 * @property {string} src
 */

const liveStreamSourceMap = {
  sd: 'private',
  preview: 'public',
};

/**
 * Format the source object to source list
 * @param {String} streamId
 * @param {String} preset
 * @param {string} previewBlurManifest
 * @param {URLSearchParams} extraQueries
 * @return {Source{}}
 */
const getLiveSource = ({
  streamId,
  preset,
  previewBlurManifest,
  extraQueries,
}) => {
  const shouldUsePreviewBlurSource =
    preset === PRESET_PREVIEW && !!previewBlurManifest;

  return {
    ...[
      { key: videoTypeKeys.HLS, format: 'm3u8' },
      { key: videoTypeKeys.DASH, format: 'mpd' },
      { key: videoTypeKeys.FLV, format: 'flv' },
    ].reduce((current, item) => {
      const url = getResourceUrl({
        endpoint: `/${streamId}/${liveStreamSourceMap[preset]}.${item.format}`,
        resourceType: WATCH,
      });
      if (shouldUsePreviewBlurSource) {
        url?.searchParams.set('preset', previewBlurManifest);
      }
      if (url && extraQueries) {
        url.search = new URLSearchParams([
          ...url.searchParams,
          ...extraQueries,
        ]);
      }
      url?.searchParams.sort();
      return url ? { ...current, [item.key]: url.href } : current;
    }, {}),
    mp4: getResourceUrl({
      endpoint: `/${streamId}/trailer.mp4`,
      resourceType: WATCH,
    })?.href,
  };
};

/**
 * Format the source object to source list
 * @param {Object} source
 * @return {Source[]}
 */
const formatSource = source => {
  let typeKeyOrder = [
    videoTypeKeys.DASH,
    videoTypeKeys.HLS,
    videoTypeKeys.MP4,
    videoTypeKeys.FLV,
  ];
  if (getKeySystem() === COM_APPLE_FPS) {
    typeKeyOrder = [
      videoTypeKeys.HLS,
      videoTypeKeys.DASH,
      videoTypeKeys.MP4,
      videoTypeKeys.FLV,
    ];
  }
  return typeKeyOrder
    .map(typeKey => {
      if (!source[typeKey]) {
        return null;
      }
      return { type: videoTypes[typeKey], src: source[typeKey] };
    })
    .filter(a => a);
};

/**
 * Format the source object to source list with DRM setting
 * @param {Object} source
 * @param {string} streamId
 * @param {function} fetchLicense
 * @param {string} keySystem - drm key system
 * @param {string} robustness - drm robustness
 * @return {Source{}}
 */
const formatSourceWithDrm = ({
  source,
  streamId,
  fetchLicense,
  keySystem,
  robustness,
}) => {
  playerLog({
    messages: ['formatSourceWithDrm'],
    data: { source, streamId, fetchLicense, keySystem, robustness },
  });
  switch (keySystem) {
    case COM_APPLE_FPS: {
      return {
        ...source,
        keySystems: {
          [`${COM_APPLE_FPS}.1_0`]: {
            audioContentType: AUDIO_CONTENT_TYPE,
            videoContentType: VIDEO_CONTENT_TYPE,
            certificateUri: getResourceUrl({
              endpoint: '/drm/certs/com.apple.fps.cer',
            }).href,
            getContentId: (emeOptions, initDataUnit16ArrayString) => {
              // https://github.com/Axinom/drm-quick-start/blob/82d81dad82b802c4332537f4964835b47601f608/Website/fairplay.html#L137
              return initDataUnit16ArrayString?.replace(/^.*:\/\//, '');
            },
            getLicense: (emeOptions, contentId, challenge, callback) => {
              playerLog({
                messages: ['formatSourceWithDrm', keySystem, 'getLicense'],
                data: { emeOptions, contentId, challenge, streamId },
              });
              fetchLicense({
                keySystem: COM_APPLE_FPS,
                challenge,
                streamId,
                callback,
              });
            },
          },
        },
      };
    }
    case COM_WIDEVINE_ALPHA: {
      return {
        ...source,
        keySystems: {
          [keySystem]: {
            audioContentType: AUDIO_CONTENT_TYPE,
            videoContentType: VIDEO_CONTENT_TYPE,
            videoRobustness: robustness,
            getLicense: (emeOptions, challenge, callback) => {
              playerLog({
                messages: ['formatSourceWithDrm', keySystem, 'getLicense'],
                data: { emeOptions, challenge, streamId },
              });
              fetchLicense({
                keySystem: COM_WIDEVINE_ALPHA,
                challenge,
                streamId,
                callback,
              });
            },
          },
        },
      };
    }
    case COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000:
    case COM_MICROSOFT_PLAYREADY_HARDWARE:
    case COM_MICROSOFT_PLAYREADY_RECOMMENDATION:
    case COM_MICROSOFT_PLAYREADY: {
      return {
        ...source,
        keySystems: {
          [keySystem]: {
            audioContentType: AUDIO_CONTENT_TYPE,
            videoContentType: VIDEO_CONTENT_TYPE,
            videoRobustness: robustness,
            getLicense: (emeOptions, challenge, callback) => {
              playerLog({
                messages: ['formatSourceWithDrm', keySystem, 'getLicense'],
                data: { emeOptions, challenge, streamId },
              });
              fetchLicense({
                challenge,
                keySystem: COM_MICROSOFT_PLAYREADY,
                streamId,
                callback,
              });
            },
          },
        },
      };
    }
  }
};

const calculateDashjsCustomizeBandwidth = ({ metricsHttpRequests }) => {
  const httpRequestsList = metricsHttpRequests.map(httpRequest => {
    const downloadBytes = httpRequest.trace?.reduce((a, b) => a + b.b[0], 0);
    const downloadDuration = httpRequest.trace?.reduce((a, b) => a + b.d, 0);
    const downloadTimeInMilliseconds =
      httpRequest._tfinish.getTime() - httpRequest.tresponse.getTime();
    return {
      downloadBytes,
      downloadTimeInMilliseconds,
      downloadDuration,
    };
  });

  const data = httpRequestsList?.reduce(
    (a, b) => {
      return {
        downloadBytes: a.downloadBytes + b.downloadBytes,
        downloadDuration: a.downloadDuration + b.downloadDuration,
        downloadTimeInMilliseconds:
          a.downloadTimeInMilliseconds + b.downloadTimeInMilliseconds,
      };
    },
    { downloadBytes: 0, downloadDuration: 0, downloadTimeInMilliseconds: 0 }
  );

  const customizeCalculateBandwidth =
    (8 * data.downloadBytes) / data.downloadTimeInMilliseconds;

  return customizeCalculateBandwidth;
};

export {
  getLiveSource,
  checkHlsSupported,
  getTimeFormat,
  formatSource,
  formatSourceWithDrm,
  calculateDashjsCustomizeBandwidth,
  defaultConfig,
  EVENT_MAP,
  TRACK,
  LOAD_STATE,
  PLAY_STATE,
};
