// NativeVideoPlayer.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EVENT_MAP } from '../resource/playerUtils.js';
import { player as playerDebug } from '../resource/debug.js';
import { withScope, captureException } from '@sentry/browser';

const playerLog = playerDebug.extend('log:NativeVideoPlayer');
const playerError = playerDebug.extend('error:NativeVideoPlayer');

export class NativeVideoPlayer extends React.PureComponent {
  videoElement = React.createRef();
  videoSource = React.createRef();

  handlePlay = event => {
    this.props.onPlay(event, event.target);
  };
  handlePause = event => this.props.onPause(event, event.target);
  handleLoadedMetadata = event =>
    this.props.onLoadedMetadata(event, event.target);

  handleCanPlayThrough = event => {
    const video = event.target;
    video.muted = this.props.isMuted;
    video.loop = this.props.isLoop;
    const promise = video.play();
    this.props.onCanPlayThrough(event, video, promise);
  };
  handleEnded = event => {
    this.props.onEnded(event, event.target);
  };

  handleError = error => {
    const video = this.videoElement.current;
    const readyState = video?.readyState;
    const isPaused = video?.paused;
    const duration = video?.duration;
    const currentSrc = this.props.trailerSource;
    withScope(scope => {
      scope.setFingerprint(['native-video-tag', error.code]);
      scope.setExtra('source', currentSrc);
      scope.setExtra('currentSrc', currentSrc);
      scope.setExtra('isPaused', isPaused);
      scope.setExtra('readyState', readyState);
      scope.setExtra('duration', duration);
      scope.setExtra('error', error);
      captureException(
        new Error(`VIDEOJS CODE ${error.code}: ${error.message}`)
      );
    });
    playerError(error);
    this.props.onError(error, video);
  };

  bindOtherListeners(video) {
    Object.keys(EVENT_MAP).forEach(key => {
      const callback = this.props[key];
      if (typeof callback === 'function') {
        const event = EVENT_MAP[key];
        const logTag = key.slice(2).toLowerCase();
        video.addEventListener(event, event => {
          callback(event, video);
          playerLog(logTag);
        });
      }
    });
  }

  attachListeners = video => {
    if (!video) return;
    video.addEventListener('play', this.handlePlay);
    video.addEventListener('pause', this.handlePause);
    video.addEventListener('loadedmetadata', this.handleLoadedMetadata);
    video.addEventListener('ended', this.handleEnded);
    video.addEventListener('canplaythrough', this.handleCanPlayThrough);
    video.addEventListener('error', this.handleError);
    this.bindOtherListeners(video);
  };

  componentDidMount() {
    const { type, trailerSource } = this.props;
    const video = this.videoElement.current;
    const source = this.videoSource.current;

    this.attachListeners(video);
    source.src = trailerSource;
    source.type = type;
    video.load();
    this.props.onSetup(video);
  }

  componentDidUpdate(prevProps) {
    const { type, trailerSource } = this.props;
    if (type !== prevProps.type || trailerSource !== prevProps.trailerSource) {
      const video = this.videoElement.current;
      const source = this.videoSource.current;
      source.src = trailerSource;
      source.type = type;
      video.load();
    }
  }

  render() {
    const { alt } = this.props;
    return (
      <StyledNativeVideoPlayer>
        <video alt={alt} ref={this.videoElement} playsInline>
          <source ref={this.videoSource}></source>
        </video>
      </StyledNativeVideoPlayer>
    );
  }
}

NativeVideoPlayer.propTypes = {
  trailerSource: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.string,
  isMuted: PropTypes.bool,
  isLoop: PropTypes.bool,
  onSetup: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onEnded: PropTypes.func,
  onError: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
};

NativeVideoPlayer.defaultProps = {
  trailerSource: '',
  type: 'video/mp4',
  isMuted: false,
  isLoop: false,
  alt: '',
  onSetup: () => null,
  onPlay: () => null,
  onPause: () => null,
  onLoadedMetadata: () => null,
  onEnded: () => null,
  onError: () => null,
  onCanPlayThrough: () => null,
};

const StyledNativeVideoPlayer = styled.div`
  width: 100%;
  height: 100%;

  video {
    width: inherit;
    height: inherit;
  }
`;

export default NativeVideoPlayer;
